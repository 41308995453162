

































import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class BaseSwitcher extends Vue {
	checked = true;

	toggleSwitch(): void {
		this.checked = !this.checked;
		this.$emit('toggled');
	}
}
